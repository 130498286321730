<template>
  <v-form ref="form" v-model="isFormValid" :disabled="loading">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12" sm="8" class="justify-end">
          <v-row>
            <v-col cols="12" sm="6">
              <date-picker-field-v2
                autofocus
                label="Data da Aquisição"
                hint="Utilize o formato YYYY-MM-DD"
                v-model="formFields.purchaseDate"
                :rules="[vRules.requiredField, vRules.validDate]"
                :picker-props="{ allowedDates: getAllowedDates }"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Cota da Aquisição"
                append-icon="mdi-cash-plus"
                :options="vOptions.positive"
                :value="formFields.purchasePrice"
                @input="formFields.purchasePrice = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Valor Aplicado"
                append-icon="mdi-cash-lock"
                :options="vOptions.positiveValue"
                :value="formFields.purchaseValue"
                @input="formFields.purchaseValue = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Valor Resgatado"
                append-icon="mdi-cash-lock-open"
                :options="vOptions.positiveValue"
                :value="formFields.redeemedValue"
                @input="formFields.redeemedValue = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12">
              <div class="text-center grey lighten-3 rounded py-1">Performance</div>
            </v-col>

            <v-col cols="12" sm="6">
              <date-picker-field-v2
                label="Data Base"
                hint="Utilize o formato YYYY-MM-DD"
                v-model="formFields.performanceFeeBaseDate"
                :rules="[vRules.validDate]"
                :picker-props="{ allowedDates: getAllowedDates }"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Cota Base"
                append-icon="mdi-cash-100"
                :options="vOptions.positive"
                :value="formFields.performanceFeeBasePrice"
                @input="formFields.performanceFeeBasePrice = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Valor Base"
                append-icon="mdi-cash-register"
                :options="vOptions.positiveValue"
                :value="formFields.performanceFeeBaseValue"
                @input="formFields.performanceFeeBaseValue = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <numeric-input
                label="Valor Acruado"
                append-icon="mdi-cash-multiple"
                :options="vOptions.negativeValue"
                :value="formFields.performanceFee"
                @input="formFields.performanceFee = vMask.unmaskNumber($event)"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col class="d-flex justify-center" cols="12" sm="1">
          <v-divider vertical />
        </v-col>

        <v-col cols="12" sm="3">
          <v-row dense>
            <v-col cols="12" class="pl-0">
              <div class="text-center grey lighten-3 rounded py-1">Retorno</div>
            </v-col>

            <v-col cols="12" class="pl-0">
              <numeric-input
                hide-details
                label="Desde o Início"
                prepend-icon="mdi-baby-carriage"
                suffix="%"
                :value="formFields.btdReturn"
                @input="formFields.btdReturn = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" class="pl-0">
              <numeric-input
                hide-details
                label="Desde a Data Base"
                prepend-icon="mdi-chart-line"
                suffix="%"
                :value="formFields.pFeeBaseReturn"
                @input="formFields.pFeeBaseReturn = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" class="pl-0">
              <numeric-input
                persistent-hint
                label="Benchmark"
                prepend-icon="mdi-chart-areaspline"
                hint="Desde a data base da perf."
                suffix="%"
                :value="formFields.pFeeBenchmarkReturn"
                @input="formFields.pFeeBenchmarkReturn = vMask.unmaskNumber($event)"
              />
            </v-col>

            <v-col cols="12" class="pl-0 mt-5">
              <div class="text-center grey lighten-3 rounded py-1">Tributação</div>
            </v-col>

            <v-col cols="12" class="pl-0">
              <date-picker-field-v2
                hide-details
                label="Data Base"
                hint="Utilize o formato YYYY-MM-DD"
                v-model="formFields.incomeTaxBaseDate"
                :rules="[vRules.validDate]"
                :picker-props="{ allowedDates: getAllowedDates }"
              />
            </v-col>

            <v-col cols="12" class="pl-0">
              <numeric-input
                hide-details
                label="Cota Base"
                append-icon="mdi-cash-100"
                :options="vOptions.positive"
                :value="formFields.incomeTaxBasePrice"
                @input="formFields.incomeTaxBasePrice = vMask.unmaskNumber($event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { unmaskNumber } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import NumericInput from '@/components/global/NumericInput.vue';

const formSchema = {
  purchaseDate: null,
  purchasePrice: null,
  purchaseValue: null,
  redeemedValue: null,

  performanceFeeBaseDate: null,
  performanceFeeBasePrice: null,
  performanceFeeBaseValue: null,
  performanceFee: null,

  btdReturn: null,
  pFeeBaseReturn: null,
  pFeeBenchmarkReturn: null,

  incomeTaxBaseDate: null,
  incomeTaxBasePrice: null,
};

export default {
  name: 'PredefinedDepositFieldsTab',

  components: {
    DatePickerFieldV2,
    NumericInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      immediate: true,
      deep: true,
      type: Object,
      default: () => ({}),
    },

    lockScope: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    isFormValid: false,

    formFields: fillSchema(formSchema, vm.initialData?.predefinedDeposit),

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
    },

    vOptions: {
      positiveValue: {
        autoDecimalDigits: true,
        valueRange: { min: 0 },
        precision: 2,
      },

      negativeValue: {
        autoDecimalDigits: true,
        valueRange: { max: 0 },
        precision: 2,
      },

      positive: {
        valueRange: { min: 0 },
      },
    },
  }),

  computed: {
    currOperation: (vm) => vm.formFields.operationType,
  },

  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler({ predefinedDeposit }) {
        this.formFields = fillSchema(formSchema, predefinedDeposit);
      },
    },

    isFormValid: {
      immediate: true,
      handler() {
        this.$emit('update:validity', this.$options.name, this.isFormValid);
      },
    },

    currOperation: {
      handler() {
        this.$emit('update:state', this.formFields);
      },
    },
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    getFormData() {
      return { predefinedDeposit: this.formFields };
    },

    clearFormData() {
      this.formFields = fillSchema(formSchema, this.initialData?.predefinedDeposit);
    },
  },
};
</script>
