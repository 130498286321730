<template>
  <v-container fluid>
    <v-scroll-x-transition hide-on-leave v-for="step in activeSteps" :key="step.component.name">
      <v-row class="mt-0" v-show="currStep === step.number">
        <component
          :is="step.component"
          :ref="step.component.name"
          :loading="loading"
          :lock-scope="lockScope"
          :initial-data="currFormState"
          @update:validity="validityUpdateHandler"
          @update:state="stateUpdateHandler"
        />
      </v-row>
    </v-scroll-x-transition>

    <v-row dense class="align-center" v-if="!hideActions">
      <v-col cols="12" sm="auto">
        <v-chip outlined small v-if="isMultiStep">{{ currStep }} de {{ totalSteps }}</v-chip>
      </v-col>

      <v-spacer />

      <v-col cols="12" sm="auto">
        <v-btn text color="error" :disabled="loading" @click="discardHandler">
          {{ texts.discardBtn }}
        </v-btn>
      </v-col>

      <v-col cols="12" sm="auto">
        <v-btn
          block
          color="primary"
          :loading="loading"
          :disabled="loading || !isFormValid"
          :outlined="isMultiStep && !isLastStep"
          @click="confirmHandler"
        >
          {{ texts.submitBtn }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseOperationFieldsTab from './BaseOperationFieldsTab.vue';
import PredefinedDepositFieldsTab from './PredefinedDepositFieldsTab.vue';
import LiabilitiesTransferFieldsTab from './LiabilitiesTransferFieldsTab.vue';

import { parseFromPercentage } from '../../../utils/parse-utils';

export default {
  name: 'QuotaholdersOperationsForm',

  components: {
    BaseOperationFieldsTab,
    PredefinedDepositFieldsTab,
    LiabilitiesTransferFieldsTab,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    lockScope: {
      type: Boolean,
      default: false,
    },

    hideActions: {
      type: Boolean,
      default: false,
    },

    discardBtnText: {
      type: String,
      default: '',
    },

    submitBtnText: {
      type: String,
      default: '',
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    currStep: 1,
    currFormState: vm.initialData, // Used only to switch between active forms and share common data across them

    formTabs: [
      {
        activationRule: (op) => op !== 'LIABILITIES_TRANSFER',
        steps: [
          {
            number: 1,
            isValid: false,
            component: BaseOperationFieldsTab,
            activationRule: (op) => op !== 'LIABILITIES_TRANSFER',
          },
          {
            number: 2,
            isValid: false,
            component: PredefinedDepositFieldsTab,
            activationRule: (op) => op === 'DEPOSIT',
          },
        ],
      },
      {
        activationRule: (op) => op === 'LIABILITIES_TRANSFER',
        steps: [
          {
            number: 1,
            isValid: false,
            component: LiabilitiesTransferFieldsTab,
            activationRule: (op) => op === 'LIABILITIES_TRANSFER',
          },
        ],
      },
    ],
  }),

  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(updatedInitialData) {
        this.currFormState = updatedInitialData;
      },
    },
  },

  computed: {
    texts: (vm) => ({
      discardBtn: vm.isMultiStep && !vm.isFirstStep ? 'Voltar' : vm.discardBtnText || 'Descartar',
      submitBtn: vm.isMultiStep && !vm.isLastStep ? 'Próximo' : vm.submitBtnText || 'Enviar',
    }),

    currOperation: (vm) => vm.currFormState.operationType,

    activeForm: (vm) => vm.formTabs.find((form) => form.activationRule(vm.currOperation)),
    activeSteps: (vm) => vm.activeForm.steps.filter((step) => step.activationRule(vm.currOperation)),

    totalSteps: (vm) => vm.activeSteps.length,
    isMultiStep: (vm) => vm.totalSteps > 1,
    isFirstStep: (vm) => vm.currStep === 1,
    isLastStep: (vm) => vm.currStep === vm.totalSteps,

    isFormValid: (vm) => vm.activeSteps.every((el) => el.isValid) || (vm.isMultiStep && !vm.isLastStep), // Always allow "Next" button to be clickable
  },

  methods: {
    validityUpdateHandler(updatedComponentName, newValidity) {
      this.activeSteps.find((step) => step.component.name === updatedComponentName).isValid = newValidity;
    },

    stateUpdateHandler(newFormFields) {
      this.currFormState = { ...this.currFormState, ...newFormFields };
    },

    discardChanges() {
      this.activeSteps.forEach((step) => this.$refs[step.component.name][0].clearFormData());
    },

    discardHandler() {
      if (this.isMultiStep && !this.isFirstStep) {
        this.currStep -= 1;
        return;
      }

      this.discardChanges();
      this.$emit('click:discard');
    },

    extractChanges() {
      return this.activeSteps.reduce(
        (formData, step) => ({ ...formData, ...this.$refs[step.component.name][0].getFormData() }),
        {},
      );
    },

    confirmHandler() {
      if (this.isMultiStep && !this.isLastStep) {
        this.currStep += 1;
        return;
      }

      const formFields = this.extractChanges();

      this.$emit('submit', {
        ...formFields,
        predefinedDeposit: {
          ...formFields?.predefinedDeposit,
          btdReturn: parseFromPercentage(formFields?.predefinedDeposit?.btdReturn),
          pFeeBaseReturn: parseFromPercentage(formFields?.predefinedDeposit?.pFeeBaseReturn),
          pFeeBenchmarkReturn: parseFromPercentage(formFields?.predefinedDeposit?.pFeeBenchmarkReturn),
        },
      });
    },
  },
};
</script>
