var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.queryPropsSet)?_c('v-data-table',_vm._g(_vm._b({class:_vm.showError ? 'checkbox-error' : '',attrs:{"item-key":"_id","loading-text":"Obtendo o passivo para os filtros selecionados...","no-data-text":("Cotista escolhido não compõe o passivo do fundo em " + _vm.refDate),"hide-default-footer":"","disable-pagination":"","single-select":"","disable-sort":"","fixed-header":"","show-select":"","dense":"","headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.isLoading ? [] : _vm.filteredTranches,"height":_vm.filteredTranches.length >= 5 ? '180px' : undefined},scopedSlots:_vm._u([{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.purchaseDate))+" ")]}},{key:"item.purchasePrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatQuota(item.purchasePrice))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity))+" ")]}},{key:"item.currentPFeeGrossValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.currentPFeeGrossValue))+" ")]}},{key:"footer",fn:function(){return [_c('v-divider')]},proxy:true}],null,false,1650231758),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',_vm.$attrs,false),_vm.$listeners)):_c('v-alert',{staticClass:"text-body-2",attrs:{"text":"","icon":"mdi-timeline-text","color":"grey darken-2"}},[_vm._v(" Insira "),_c('b',[_vm._v("Fundo da Casa")]),_vm._v(", "),_c('b',[_vm._v("Data da Cotização")]),_vm._v(" e "),_c('b',[_vm._v("Cotista")]),_vm._v(" para selecionar os passivos ")]),_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showError),expression:"showError"}],staticClass:"v-messages error--text"},[_vm._v("Campo obrigatório")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }