<template>
  <v-form ref="form" v-model="isOperationFormValid" :disabled="loading">
    <v-container fluid>
      <v-row>
        <v-col cols="12" :sm="isFieldHidden.redemptionCriterion ? 8 : 4">
          <house-funds-selector
            label="Fundo da Casa"
            :hint="lockScope ? 'Impossível editar' : 'Clique para selecionar'"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            autofocus
            item-text="shortName"
            item-value="_id"
            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
            :readonly="lockScope"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"
            :hint="lockScope ? 'Impossível editar' : 'Clique para selecionar'"
            append-icon="mdi-swap-horizontal-bold"
            ref="operation"
            persistent-hint
            v-model="formFields.operationType"
            :items="operationTypeOptions"
            :readonly="lockScope"
            @input="clearUnusedFields"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>

        <v-scroll-x-transition hide-on-leave>
          <v-col cols="12" sm="4" v-show="!isFieldHidden.redemptionCriterion">
            <v-select
              label="Critério de Resgate"
              append-icon="mdi-arrow-decision"
              hint="Regra para impacto do passivo"
              ref="criteria"
              persistent-hint
              v-model="formFields.redemptionCriterion"
              :items="redemptionCriterionOptions"
              :rules="isFieldHidden.redemptionCriterion ? [] : [vRules.requiredField]"
              @focus="showCriteriaOptions"
              @mousedown.prevent
            />
          </v-col>
        </v-scroll-x-transition>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Operação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.commandDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Cotização"
            :hint="lockScope ? 'Impossível editar' : 'Utilize o formato YYYY-MM-DD'"
            :persistent-hint="lockScope"
            v-model="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
            :readonly="lockScope"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Liquidação"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.settlementDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <quotaholders-selector
            label="Cotista"
            append-icon="mdi-handshake"
            hint="Digite para buscar"
            persistent-hint
            item-text="name"
            item-value="_id"
            v-model.trim="formFields.quotaholderId"
            :rules="isFieldDisabled.quotaholderId ? [] : [vRules.requiredField]"
            :disabled="isFieldDisabled.quotaholderId"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Quantidade"
            append-icon="mdi-counter"
            :rules="isFieldDisabled.quantity ? [] : [vRules.requiredField]"
            :disabled="isFieldDisabled.quantity"
            :value="formFields.quantity"
            @input="formFields.quantity = vMask.unmaskNumber($event)"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <numeric-input
            label="Valor"
            append-icon="mdi-cash-multiple"
            :rules="isFieldDisabled.value ? [] : [vRules.requiredField]"
            :disabled="isFieldDisabled.value"
            :options="vOptions.value"
            :value="formFields.value"
            :key="formFields.operationType"
            @input="formFields.value = vMask.unmaskNumber($event)"
          />
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-show="!isFieldHidden.affectedLiabilities">
          <v-col class="pb-0" cols="12" sm="12">
            <span>Passivos Impactados</span>
          </v-col>
          <v-col cols="12">
            <liabilities-selector
              required
              :houseFundId="formFields.houseFundId"
              :quotaholderId="formFields.quotaholderId"
              :refDate="liabilitiesRefDate"
              :initial-data="initialData.affectedLiabilities"
              @update:selection="affectedLiabilitiesHandler"
            />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { unmaskNumber } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import LiabilitiesSelector from '@/components/global/LiabilitiesSelector.vue';
import NumericInput from '@/components/global/NumericInput.vue';
import QuotaholdersSelector from '@/components/global/QuotaholdersSelector.vue';

const formSchema = {
  houseFundId: null,
  operationType: 'BUY',
  redemptionCriterion: null,

  commandDate: null,
  refDate: null,
  settlementDate: null,

  quotaholderId: null,
  quantity: null,
  value: null,

  affectedLiabilities: [],
};

export default {
  name: 'BaseOperationFieldsTab',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    NumericInput,
    QuotaholdersSelector,
    LiabilitiesSelector,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    lockScope: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    isOperationFormValid: false,
    isLiabilitiesFieldValid: false,

    formFields: fillSchema(formSchema, vm.initialData),

    vRules: {
      requiredField,
      validDate,
    },

    vMask: {
      unmaskNumber,
    },

    vOptions: {
      value: {
        autoDecimalDigits: true,
        precision: 2,
      },
    },

    operationTypeOptions: [
      { value: 'BUY', text: 'Aplicação' },
      { value: 'SELL', text: 'Resgate' },
      { value: 'DEPOSIT', text: 'Depósito' },
      { value: 'WITHDRAWAL', text: 'Retirada' },
      { value: 'TOTAL_REDEMPTION', text: 'Resgate Total' },
      { value: 'TAX_REDEMPTION', text: 'Tributação' },
      { value: 'AMORTIZATION', text: 'Amortização' },
      { value: 'LIABILITIES_TRANSFER', text: 'STVM' },
    ],

    redemptionCriterionOptions: [
      { value: 'FIFO', text: 'Antigos Primeiro (FIFO)' },
      { value: 'LIFO', text: 'Novos Primeiro (LIFO)' },
      { value: 'PREDEFINED', text: 'Predefinido' },
    ],
  }),

  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(updatedInitialData) {
        this.formFields = fillSchema(formSchema, updatedInitialData);
      },
    },

    isFormValid: {
      immediate: true,
      handler() {
        this.$emit('update:validity', this.$options.name, this.isFormValid);
      },
    },

    currOperation: {
      handler() {
        this.$emit('update:state', this.formFields);
      },
    },
  },

  computed: {
    currOperation: (vm) => vm.formFields.operationType,

    liabilitiesRefDate: (vm) => (vm.formFields.refDate
      ? moment(vm.formFields.refDate).businessSubtract(1, 'd', 'brasil').format('YYYY-MM-DD')
      : null),

    isFieldHidden: (vm) => ({
      redemptionCriterion: !['SELL', 'WITHDRAWAL', 'TAX_REDEMPTION'].includes(
        vm.formFields.operationType,
      ),
      affectedLiabilities: vm.formFields.redemptionCriterion !== 'PREDEFINED',
    }),

    isFieldDisabled: (vm) => ({
      value: vm.formFields.operationType === 'TOTAL_REDEMPTION',
      quantity: !['DEPOSIT', 'WITHDRAWAL'].includes(vm.formFields.operationType),
      quotaholderId: vm.formFields.operationType === 'AMORTIZATION',
    }),

    isFormValid: (vm) => vm.isOperationFormValid
      && (vm.isFieldHidden.affectedLiabilities || vm.isLiabilitiesFieldValid),
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    getFormData() {
      return this.formFields;
    },

    clearFormData() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    clearUnusedFields() {
      Object.keys(this.formFields).forEach((field) => {
        if (this.isFieldDisabled[field] || this.isFieldHidden[field]) {
          this.formFields[field] = formSchema[field];
        }
      });
    },

    affectedLiabilitiesHandler(selectedLiabilities, liabilitiesValidity) {
      this.formFields.affectedLiabilities = selectedLiabilities.map((tranche) => tranche._id);
      this.isLiabilitiesFieldValid = liabilitiesValidity;
    },

    showOperationOptions() {
      this.$refs.operation.activateMenu();
    },

    showCriteriaOptions() {
      this.$refs.criteria.activateMenu();
    },
  },
};
</script>
