import { sumBy, uniq } from 'lodash';
import { formatDate } from '@/utils/format-utils';

type QuotaholderOperation = Record<string, any>;

export function getOverview(operations: QuotaholderOperation[]) {
  return {
    totalCount: operations.length,
    totalQuantity: sumBy(operations, 'quantity'),
    totalValue: sumBy(operations, 'value'),
  };
}

export function getFilterOptions(operations: QuotaholderOperation[]) {
  return {
    quotaholderName: uniq(operations.map((item) => item.quotaholderName)).sort(),
    redemptionCriterion: uniq(operations.map((item) => item.redemptionCriterion)).sort(),
    refDate: uniq(operations.map((item) => item.refDate)).sort().reverse().map((item) => ({
      value: item,
      text: formatDate(item),
    })),
    settlementDate: uniq(operations.map((item) => item.settlementDate)).sort().reverse().map((item) => ({
      value: item,
      text: formatDate(item),
    })),
  };
}

export function filterResults(results: Record<string, any>[], filters: Record<string, any>) {
  return results.map((row) => {
    const operations = row.operations.filter((operation: QuotaholderOperation) => {
      const rules: boolean[] = [
        !filters.quotaholderName.length || filters.quotaholderName.includes(operation.quotaholderName),
        !filters.refDate.length || filters.refDate.includes(operation.refDate),
        !filters.settlementDate.length || filters.settlementDate.includes(operation.settlementDate),
        !filters.redemptionCriterion.length || filters.redemptionCriterion.includes(operation.redemptionCriterion),
      ];

      return rules.every((rule) => rule);
    });

    return {
      ...row,
      operations,
      key: `${row.houseFundId}_${row.commandDate}`,
      count: operations.length,
      totalValue: sumBy(operations, 'value'),
    };
  }).filter((item) => item.operations.length);
}
