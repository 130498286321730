<template>
  <v-container fluid class="quotaholdersoperations-view">
    <h1 class="text-h5">Operações de Cotistas</h1>

    <request-filters :filters.sync="requestFilters" />

    <div class="text-right mb-4">
      <v-btn small rounded color="primary" class="mx-1" @click="() => fetchData()">
        <v-icon small left> mdi-refresh </v-icon>

        Atualizar
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        :disabled="!isElevatedUser"
        @click="() => showCreationDialog()"
      >
        <v-icon small left> mdi-plus </v-icon>

        Novo
      </v-btn>

      <v-btn
        small
        rounded
        color="primary"
        class="mx-1"
        :disabled="!isElevatedUser"
        @click="() => showBatchDialog()"
      >
        <v-icon small left> mdi-plus-box-multiple </v-icon>

        Lote
      </v-btn>
    </div>

    <v-card class="mb-4" :loading="isFetchingData">
      <v-card-title>
        Overview

        <v-spacer />
        <v-btn
          class="mr-2 pr-3"
          small
          rounded
          color="primary"
          :disabled="!hasResults"
          @click="downloadCsv"
        >
          <v-icon small left> mdi-microsoft-excel </v-icon>
          Baixar
        </v-btn>

        <v-badge overlap color="info" class="mx-1" :content="filterCount" :value="filterCount > 0">
          <v-btn
            small
            rounded
            color="primary"
            :disabled="!hasResults"
            @click="() => showFiltersDialog()"
          >
            <v-icon small left> mdi-filter </v-icon>

            Filtros
          </v-btn>
        </v-badge>
      </v-card-title>

      <quotaholders-operations-overview :overview="resultsOverview" />
    </v-card>

    <v-card>
      <v-card-text>
        <quotaholders-operations-table
          fixed-header
          dense
          :items="filteredResults"
          :loading="isFetchingData"
          must-sort
          sort-by="refDate"
          sort-desc
          hide-default-footer
          :items-per-page="-1"
          @click:delete="(ops) => showBulkDeletionDialog(ops)"
        >
          <template v-slot:details="{ item }">
            <quotaholders-operations-details-table
              fixed-header
              dense
              :items="item.operations"
              :readonly="!isElevatedUser"
              must-sort
              :sort-by.sync="detailsSortBy"
              :sort-desc.sync="detailsSortDesc"
              hide-default-footer
              :items-per-page="-1"
              @click:filter="() => showFiltersDialog()"
              @click:edit="(op) => showEditDialog(op)"
              @click:delete="(op) => showDeletionDialog(op)"
            />
          </template>
        </quotaholders-operations-table>
        <v-row v-if="showLoadMore" class="my-2" align="center" justify="space-around">
          <v-btn :loading="isFetchingData" @click="() => loadMore()" small color="primary"
            >Carregar mais</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="isFilterDialogOpen" persistent max-width="400">
      <v-card>
        <v-card-title> Filtrar itens </v-card-title>

        <quotaholders-operations-filters
          :filters.sync="detailsFilters"
          :filter-options="filterOptions"
        />

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="() => hideFiltersDialog()"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isCreationDialogOpen" max-width="900">
      <v-card>
        <v-card-title class="justify-center white--text primary">
          Nova Operação de Cotistas
        </v-card-title>

        <br />

        <v-card-text class="py-0">
          <quotaholders-operations-form
            ref="creationForm"
            :loading="isSendingData"
            discard-btn-text="Cancelar"
            @click:discard="() => hideCreationDialog()"
            @submit="(fields) => createItem(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isBatchDialogOpen" max-width="800">
      <batch-operations-dialog
        title="Operações de Cotistas em Lote"
        @click:cancel="hideBatchDialog"
        :headers="batchFields"
        @click:submit="(fields) => createItem(fields)"
      />
    </v-dialog>

    <v-dialog v-model="isEditionDialogOpen" max-width="900" @click:outside="restoreEditionData">
      <v-card>
        <v-card-title class="justify-center white--text primary">
          Editar Operação de Cotistas
        </v-card-title>

        <br />

        <v-card-text class="py-0">
          <quotaholders-operations-form
            ref="editionForm"
            :loading="isSendingData"
            :initial-data="selectedItem || {}"
            lock-scope
            discard-btn-text="Cancelar"
            @click:discard="() => hideEditionDialog()"
            @submit="(fields) => editItem(fields)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <contextual-dialog
      v-model="isDeletionDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"
      title="Deletar operação?"
      message="Deseja realmente deletar a operação?"
    >
      <v-card-actions class="justify-center">
        <v-btn color="primary" :disabled="isSendingData" @click="() => deleteItem()">
          Deletar
        </v-btn>
      </v-card-actions>
    </contextual-dialog>

    <contextual-dialog
      v-model="isBulkDeletionDialogOpen"
      icon="mdi-delete"
      :icon-color="isSendingData ? 'grey' : 'error'"
      :loading="isSendingData"
      title="Deletar Operações"
      message="Deseja realmente deletar TODAS essas operações no fundo na data?"
    >
      <v-card-actions class="justify-center">
        <v-btn color="error" :disabled="isSendingData" @click="() => bulkDeleteItems()">
          Deletar tudo
        </v-btn>
      </v-card-actions>
    </contextual-dialog>
  </v-container>
</template>

<script>
import api from '@/services/api';
import { generateCSV } from '@/utils/json-to-csv';
import { debounce, flatMap, omit } from 'lodash';
import moment from 'moment-loyall';

import ContextualDialog from '@/components/global/ContextualDialog.vue';

import {
  parseDate,
  parseFromPercentage,
  parseToPercentage,
  removeEmpties,
} from '../../utils/parse-utils';
import { filterResults, getFilterOptions, getOverview } from './utils/data-handling-utils';
import { getApiErrors, parseRequestFilters } from './utils/request-utils';

import BatchOperationsDialog from '../operations/components/BatchOperationsDialog.vue';
import QuotaholdersOperationsDetailsTable from './QuotaholdersOperationsDetailsTable.vue';
import QuotaholdersOperationsFilters from './QuotaholdersOperationsFilters.vue';
import QuotaholdersOperationsForm from './form/QuotaholdersOperationsForm.vue';
import QuotaholdersOperationsOverview from './QuotaholdersOperationsOverview.vue';
import QuotaholdersOperationsTable from './QuotaholdersOperationsTable.vue';
import RequestFilters from './RequestFilters.vue';

export default {
  name: 'QuotaholdersOperationsView',

  components: {
    ContextualDialog,
    RequestFilters,
    QuotaholdersOperationsFilters,
    QuotaholdersOperationsForm,
    QuotaholdersOperationsTable,
    QuotaholdersOperationsDetailsTable,
    QuotaholdersOperationsOverview,
    BatchOperationsDialog,
  },

  data: () => ({
    isFetchingData: false,
    results: [],
    total: 0,

    requestFilters: {
      houseFundIds: [],
      initialDate: moment().businessSubtract(5, 'days', 'brasil').format('YYYY-MM-DD'),
      finalDate: null,
    },

    isFilterDialogOpen: false,
    detailsFilters: {
      quotaholderName: [],
      refDate: [],
      settlementDate: [],
      redemptionCriterion: [],
    },

    detailsSortBy: 'value',
    detailsSortDesc: true,

    selectedItem: null,
    isSendingData: false,

    isCreationDialogOpen: false,
    isEditionDialogOpen: false,
    isDeletionDialogOpen: false,
    isBulkDeletionDialogOpen: false,

    isBatchDialogOpen: false,
    batchFields: [
      'houseFundId',
      'operationType',
      'redemptionCriterion',
      'commandDate',
      'refDate',
      'settlementDate',
      'quotaholderId',
      'quantity',
      'value',
    ],
  }),

  computed: {
    isElevatedUser: (vm) => ['admin', 'backoffice'].includes(vm.$store.state.user?.acl),

    hasResults: (vm) => vm.results.length > 0,
    flatResults: (vm) => flatMap(vm.results, 'operations'),

    filterCount: (vm) => Object.values(vm.detailsFilters).filter((val) => (Array.isArray(val) ? val.length > 0 : !!val)).length,
    filterOptions: (vm) => getFilterOptions(vm.flatResults),
    filteredResults: (vm) => filterResults(vm.results, vm.detailsFilters),
    flatFilteredResults: (vm) => flatMap(vm.filteredResults, 'operations'),

    resultsOverview: (vm) => getOverview(vm.flatFilteredResults),
    showLoadMore: (vm) => vm.total > vm.results.length,
  },

  watch: {
    requestFilters: {
      deep: true,
      immediate: true,
      handler() {
        this.fetchData();
      },
    },

    isDeletionDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }
    },

    isBulkDeletionDialogOpen(newState) {
      if (!newState) {
        this.selectedItem = null;
      }
    },
  },

  created() {
    this.$store.dispatch('houseFunds/fetchList');
  },

  methods: {
    downloadCsv() {
      const data = this.filteredResults
        .map(({
          houseFundId, name, commandDate, operations,
        }) => operations.map((operation) => {
          const cleanOperation = operation;

          delete cleanOperation._id;

          return {
            houseFundId,
            name,
            commandDate,
            ...cleanOperation,
          };
        }))
        .reduce((pre, cur) => pre.concat(cur));

      generateCSV(data);
    },

    showFiltersDialog() {
      this.isFilterDialogOpen = true;
    },

    hideFiltersDialog() {
      this.isFilterDialogOpen = false;
      this.fetchData();
    },

    showBatchDialog() {
      this.isBatchDialogOpen = true;
    },

    hideBatchDialog() {
      this.isBatchDialogOpen = false;
    },

    showEditDialog(operation) {
      const operationData = { ...operation };

      const baseOperationParsers = {
        refDate: parseDate,
        commandDate: parseDate,
        settlementDate: parseDate,
      };

      const predefinedDepositParsers = {
        purchaseDate: parseDate,
        incomeTaxBaseDate: parseDate,
        performanceFeeBaseDate: parseDate,
        pFeeBaseReturn: parseToPercentage,
        pFeeBenchmarkReturn: parseToPercentage,
        btdReturn: parseToPercentage,
      };

      Object.entries(baseOperationParsers).forEach(([field, parser]) => {
        if (operationData[field]) {
          operationData[field] = parser(operationData[field]);
        }
      });

      Object.entries(predefinedDepositParsers).forEach(([field, parser]) => {
        if (operationData?.predefinedDeposit?.[field]) {
          operationData.predefinedDeposit[field] = parser(operationData.predefinedDeposit[field]);
        }
      });

      this.selectedItem = operationData;
      this.isEditionDialogOpen = true;

      if (this.$refs.editionForm) {
        this.$nextTick(() => this.$refs.editionForm.discardChanges());
      }
    },

    restoreEditionData() {
      const predefinedDepositParsers = {
        pFeeBaseReturn: parseFromPercentage,
        pFeeBenchmarkReturn: parseFromPercentage,
        btdReturn: parseFromPercentage,
      };

      Object.entries(predefinedDepositParsers).forEach(([field, parser]) => {
        if (this.selectedItem?.predefinedDeposit?.[field]) {
          this.selectedItem.predefinedDeposit[field] = parser(
            this.selectedItem.predefinedDeposit[field],
          );
        }
      });
    },

    hideEditionDialog() {
      this.isEditionDialogOpen = false;

      if (this.selectedItem) {
        this.restoreEditionData();
        this.selectedItem = null;
      }

      if (this.$refs.editionForm) {
        this.$nextTick(() => this.$refs.editionForm.discardChanges());
      }
    },

    showDeletionDialog(operation) {
      this.selectedItem = operation;
      this.isDeletionDialogOpen = true;
    },

    hideDeletionDialog() {
      this.isDeletionDialogOpen = false;
    },

    showBulkDeletionDialog(operations) {
      this.selectedItem = operations;
      this.isBulkDeletionDialogOpen = true;
    },

    hideBulkDeletionDialog() {
      this.isBulkDeletionDialogOpen = false;
    },

    showCreationDialog() {
      this.isCreationDialogOpen = true;
    },

    hideCreationDialog() {
      this.isCreationDialogOpen = false;

      this.$nextTick(() => this.$refs.creationForm?.discardChanges());
    },

    async loadMore() {
      this.isFetchingData = true;

      try {
        const { data } = await api.quotaholdersOperations.getQuotaholdersOperationsStatements({
          params: {
            ...parseRequestFilters(this.requestFilters),
            ...this.detailsFilters,
            offset: this.filteredResults.length,
          },
        });
        this.total = data.total;
        this.results = [...this.results, ...data.results] ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    fetchData: debounce(async function debouncedFetchData() {
      this.isFetchingData = true;

      try {
        const { data } = await api.quotaholdersOperations.getQuotaholdersOperationsStatements({
          params: { ...parseRequestFilters(this.requestFilters), ...this.detailsFilters },
        });
        this.total = data.total;
        this.results = data.results ?? [];
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível completar a requisição',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isFetchingData = false;
      }
    }, 200),

    async createItem(rawData) {
      this.isSendingData = true;

      try {
        const newData = removeEmpties(rawData);

        switch (newData.operationType) {
          case 'AMORTIZATION':
            await api.quotaholdersOperations.createQuotaholdersAmortization({
              operations: [omit(newData, 'operationType')],
            });
            break;

          case 'LIABILITIES_TRANSFER':
            await api.quotaholdersOperations.createQuotaholdersLiabilitiesTransfer({
              operations: [omit(newData, 'operationType')],
            });
            break;

          default:
            await api.quotaholdersOperations.createQuotaholdersOperation({
              operations: Array.isArray(rawData) ? rawData : [newData],
            });
            break;
        }

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação adicionada com sucesso!',
          message: 'Os dados da operação foram inseridos corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível inserir a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async editItem(rawData) {
      this.isSendingData = true;

      try {
        const newData = removeEmpties(rawData);

        await api.quotaholdersOperations.updateQuotaholdersOperation(
          this.selectedItem._id,
          newData,
        );

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação atualizada com sucesso!',
          message: 'Os dados da operação foram atualizados corretamente.',
        });

        this.fetchData();
        this.hideEditionDialog();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível editar a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.isSendingData = false;
      }
    },

    async deleteItem() {
      this.isSendingData = true;

      try {
        await api.quotaholdersOperations.deleteQuotaholdersOperation(this.selectedItem._id);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operação excluída com sucesso!',
          message: 'Os dados da operação foram removidos corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível deletar a operação',
          errorList: getApiErrors(error),
        });
      } finally {
        this.hideDeletionDialog();
        this.isSendingData = false;
      }
    },

    async bulkDeleteItems() {
      this.isSendingData = true;

      try {
        const operationIds = this.selectedItem.operations.map((op) => op._id);
        await api.quotaholdersOperations.bulkDeleteQuotaholdersOperations(operationIds);

        this.$store.dispatch('alert/showAlert', {
          icon: 'mdi-check-bold',
          iconColor: 'success',
          title: 'Operações excluídas com sucesso!',
          message: 'Os dados das operações foram removidos corretamente.',
        });

        this.fetchData();
      } catch (error) {
        this.$store.dispatch('alert/showAlert', {
          title: 'Algo de errado aconteceu!',
          message: 'Não foi possível deletar as operações',
          errorList: getApiErrors(error),
        });
      } finally {
        this.hideBulkDeletionDialog();
        this.isSendingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  th {
    white-space: nowrap;
  }
}
</style>
