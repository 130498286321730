<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    item-key="key"
    show-expand
  >
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        icon
        color="error"
        @click="() => $emit('click:delete', item)"
      >
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:item.commandDate="{ item }">
      {{ formatDate(item.commandDate) }}
    </template>

    <template v-slot:item.count="{ item }">
      {{ formatNumber(item.count) }}
    </template>

    <template v-slot:item.totalValue="{ item }">
      <span :class="{ 'error--text': item.totalValue < 0 }">
        {{ formatMoney(item.totalValue) }}
      </span>
    </template>

    <template v-slot:expanded-item="{ item, headers }">
      <td :colspan="headers.length" class="pa-3">
        <slot name="details" :item="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate, formatMoney, formatNumber } from '@/utils/format-utils';

export default {
  name: 'QuotaholdersOperationsTable',

  inheritAttrs: false,

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Apagar',
        width: '40px',
        sortable: false,
        groupable: false,
      },

      {
        value: 'houseFundName',
        text: 'Fundo',
      },

      {
        value: 'commandDate',
        text: 'Data de registro',
      },

      {
        value: 'count',
        text: 'Nº operações',
        align: 'end',
      },

      {
        value: 'totalValue',
        text: 'Valor total',
        align: 'end',
      },

      {
        value: 'data-table-expand',
        text: '',
      },
    ],
  }),

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
  },
};
</script>
