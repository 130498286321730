<template>
  <v-form ref="form" v-model="isFormValid" :disabled="loading">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="8">
          <house-funds-selector
            label="Fundo da Casa"
            hint="Clique para selecionar"
            append-icon="mdi-home-currency-usd"
            persistent-hint
            item-text="shortName"
            item-value="_id"
            v-model="formFields.houseFundId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            label="Operação"
            :hint="lockScope ? 'Impossível editar' : 'Clique para selecionar'"
            append-icon="mdi-swap-horizontal-bold"
            ref="operation"
            persistent-hint
            v-model="formFields.operationType"
            :items="operationTypeOptions"
            :readonly="lockScope"
            @focus="showOperationOptions"
            @mousedown.prevent
          />
        </v-col>

        <v-col cols="12" sm="4">
          <date-picker-field-v2
            label="Data da Transferência"
            hint="Utilize o formato YYYY-MM-DD"
            v-model="formFields.refDate"
            :rules="[vRules.requiredField, vRules.validDate]"
            :picker-props="{ allowedDates: getAllowedDates }"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <quotaholders-selector
            label="Cotista de Origem"
            append-icon="mdi-account-arrow-right"
            hint="Digite para buscar"
            persistent-hint
            item-text="name"
            item-value="_id"
            v-model="formFields.originQuotaholderId"
            :rules="[vRules.requiredField]"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <quotaholders-selector
            label="Cotista de Destino"
            append-icon="mdi-account-arrow-left"
            hint="Digite para buscar"
            persistent-hint
            item-text="name"
            item-value="_id"
            v-model="formFields.targetQuotaholderId"
            :rules="[vRules.requiredField]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from 'moment-loyall';

import fillSchema from '@/utils/fill-schema';
import { requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import HouseFundsSelector from '@/components/global/HouseFundsSelector.vue';
import QuotaholdersSelector from '@/components/global/QuotaholdersSelector.vue';

const formSchema = {
  houseFundId: null,
  operationType: 'SECURITIES_TRANSFER',
  refDate: null,
  originQuotaholderId: null,
  targetQuotaholderId: null,
};

export default {
  name: 'SecuritiesTransferFieldsTab',

  components: {
    DatePickerFieldV2,
    HouseFundsSelector,
    QuotaholdersSelector,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Object,
      default: () => ({}),
    },

    lockScope: {
      type: Boolean,
      default: false,
    },
  },

  data: (vm) => ({
    isFormValid: false,

    formFields: fillSchema(formSchema, vm.initialData),

    vRules: {
      requiredField,
      validDate,
    },

    operationTypeOptions: [
      { value: 'BUY', text: 'Aplicação' },
      { value: 'SELL', text: 'Resgate' },
      { value: 'DEPOSIT', text: 'Depósito' },
      { value: 'WITHDRAWAL', text: 'Retirada' },
      { value: 'TOTAL_REDEMPTION', text: 'Resgate Total' },
      { value: 'TAX_REDEMPTION', text: 'Tributação' },
      { value: 'AMORTIZATION', text: 'Amortização' },
      { value: 'LIABILITIES_TRANSFER', text: 'STVM' },
    ],
  }),

  computed: {
    currOperation: (vm) => vm.formFields.operationType,
  },

  watch: {
    initialData: {
      immediate: true,
      deep: true,
      handler(updatedInitialData) {
        this.formFields = fillSchema(formSchema, updatedInitialData);
      },
    },

    isFormValid: {
      immediate: true,
      handler() {
        this.$emit('update:validity', this.$options.name, this.isFormValid);
      },
    },

    currOperation: {
      handler() {
        this.$emit('update:state', this.formFields);
      },
    },
  },

  methods: {
    getAllowedDates: (date) => moment(date).isBusinessDay('brasil'),

    getFormData() {
      return this.formFields;
    },

    clearFormData() {
      this.formFields = fillSchema(formSchema, this.initialData);
    },

    showOperationOptions() {
      this.$refs.operation.activateMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mdi-account-arrow-right {
    transform: none !important; // disable flip
  }
  .mdi-account-arrow-left {
    transform: none !important; // disable flip
  }
}
</style>
