<template>
  <div>
    <v-data-table
      v-if="queryPropsSet"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="selected"

      item-key="_id"
      loading-text="Obtendo o passivo para os filtros selecionados..."
      :no-data-text="`Cotista escolhido não compõe o passivo do fundo em ${refDate}`"

      hide-default-footer
      disable-pagination
      single-select
      disable-sort
      fixed-header
      show-select
      dense

      :headers="headers"
      :loading="isLoading"
      :items="isLoading ? [] : filteredTranches"
      :class="showError ? 'checkbox-error' : ''"
      :height="filteredTranches.length >= 5 ? '180px' : undefined"
    >
      <template v-slot:item.purchaseDate="{ item }">
        {{ formatDate(item.purchaseDate) }}
      </template>

      <template v-slot:item.purchasePrice="{ item }">
        {{ formatQuota(item.purchasePrice) }}
      </template>

      <template v-slot:item.quantity="{ item }">
        {{ formatNumber(item.quantity) }}
      </template>

      <template v-slot:item.currentPFeeGrossValue="{ item }">
        {{ formatMoney(item.currentPFeeGrossValue) }}
      </template>

      <template v-slot:footer>
        <v-divider />
      </template>
    </v-data-table>

    <v-alert v-else text icon="mdi-timeline-text" color="grey darken-2" class="text-body-2">
      Insira <b>Fundo da Casa</b>, <b>Data da Cotização</b> e <b>Cotista</b> para selecionar os
      passivos
    </v-alert>

    <v-scroll-y-transition hide-on-leave>
      <div v-show="showError" class="v-messages error--text">Campo obrigatório</div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import api from '@/services/api';
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatQuota,
} from '@/utils/format-utils';
import { debounce } from 'lodash';

export default {
  name: 'LiabilitiesSelector',

  props: {
    houseFundId: {
      type: String || null,
      default: null,
    },

    quotaholderId: {
      type: String || null,
      default: null,
    },

    refDate: {
      type: String || null,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    initialData: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    isLoading: false,
    focusedOnce: false,

    filteredTranches: [],
    selected: [],

    headers: [
      {
        text: 'Data Aplicação',
        value: 'purchaseDate',
      },
      {
        text: 'Cota Aplicação',
        value: 'purchasePrice',
      },
      {
        text: 'Quantidade',
        value: 'quantity',
      },
      {
        text: 'Valor Bruto Perf.',
        value: 'currentPFeeGrossValue',
      },
    ],
  }),

  computed: {
    queryPropsSet: (vm) => vm.houseFundId && vm.refDate && vm.quotaholderId,

    isValid: (vm) => !vm.required || vm.selected.length,

    showError: (vm) => vm.queryPropsSet && vm.focusedOnce && !vm.isValid,
  },

  watch: {
    houseFundId: {
      immediate: true,
      async handler() {
        await this.fetchData();
      },
    },
    quotaholderId: {
      immediate: true,
      async handler() {
        await this.fetchData();
      },
    },
    refDate: {
      immediate: true,
      async handler() {
        await this.fetchData();
      },
    },
    initialData: {
      immediate: true,
      deep: true,
      async handler() {
        await this.fetchData();
      },
    },

    selected: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.focusedOnce && this.selected.length) {
          this.focusedOnce = true;
        }

        this.$emit('update:selection', this.selected, this.isValid);
      },
    },
  },

  methods: {
    formatDate,
    formatQuota,
    formatNumber,
    formatMoney,

    fetchData: debounce(async function debouncedFetchData() {
      this.isLoading = true;

      try {
        if (!this.queryPropsSet) return;

        const { data } = await api.liabilities.getLiabilitiesDetails(
          this.houseFundId,
          this.refDate,
        );

        const tranches = data?.liabilities ?? [];

        this.filteredTranches = tranches
          .filter((tranche) => tranche.quotaholderId === this.quotaholderId)
          .sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));

        // Pre-select tranches from initialData if any
        this.selected = this.filteredTranches.filter((tranche) => this.initialData.includes(tranche._id));
      } catch (error) {
        const errorMessage = error.response?.data.message ?? error.message;
        this.$store.dispatch('alert/showAlert', { errorList: [errorMessage] });
      } finally {
        this.isLoading = false;
      }
    }, 750),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .checkbox-error table i {
    color: #ff5252 !important;
  }
}
</style>
