<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    :headers="headers"
    :items="items"
    item-key="_id"
  >
    <template v-slot:body.prepend>
      <tr class="totals-row" v-if="!hideOverview">
        <td>
          <v-btn small icon color="white" @click="() => $emit('click:filter')">
            <v-icon small>
              mdi-filter
            </v-icon>
          </v-btn>
        </td>

        <td colspan="2" />

        <td :class="['text-right', { 'error--text': overview.totalValue < 0 }]">
          {{ formatMoney(overview.totalValue) }}
        </td>

        <td colspan="3" />

        <td :class="['text-right', { 'error--text': overview.totalQuantity < 0 }]">
          {{ formatNumber(overview.totalQuantity) }}
        </td>

        <td colspan="2" />
      </tr>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn small icon color="primary" :disabled="readonly" @click="() => $emit('click:edit', item)">
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <v-btn small icon color="error" :disabled="readonly" @click="() => $emit('click:delete', item)">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>

      <v-tooltip top>
        <template v-slot:activator="tooltipProps">
          <v-btn
            small
            icon
            color="primary"
            @click="() => copyId(item._id)"

            v-bind="tooltipProps.attrs"
            v-on="tooltipProps.on"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:default>
          Copiar _id
        </template>
      </v-tooltip>
    </template>

    <!-- <template v-slot:item.quotaholderName="{ item }">
      {{ item.quotaholderName }}

      <v-btn x-small :to="`/quotaholders/${item.quotaholderId}`">
        <v-icon small>
          mdi-card-account-details
        </v-icon>
      </v-btn>
    </template> -->

    <template v-slot:item.value="{ item }">
      <span :class="{ 'error--text': item.value < 0 }">
        {{ formatMoney(item.value) }}
      </span>
    </template>

    <template v-slot:item.refDate="{ item }">
      {{ formatDate(item.refDate) }}
    </template>

    <template v-slot:item.settlementDate="{ item }">
      {{ formatDate(item.settlementDate) }}
    </template>

    <template v-slot:item.unitaryPrice="{ item }">
      {{ formatNumber(item.unitaryPrice) }}
    </template>

    <template v-slot:item.quantity="{ item }">
      {{ formatNumber(item.quantity) }}
    </template>

    <template v-slot:item.performanceFee="{ item }">
      {{ formatPercentage(item.performanceFee) }}
    </template>

    <template v-slot:item.redemptionCriterion="{ item }">
      {{ item.redemptionCriterion || 'Nenhum' }}
    </template>
  </v-data-table>
</template>

<script>
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatPercentage,
} from '@/utils/format-utils';

import copyToClipboard from '@/utils/copy-to-clipboard';

import { getOverview } from './utils/data-handling-utils';

export default {
  name: 'QuotaholdersOperationsDetailsTable',

  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    hideOverview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        value: 'actions',
        text: 'Ações',
        sortable: false,
        groupable: false,
        width: '120px',
      },

      {
        value: 'quotaholderName',
        text: 'Cotista',
      },

      {
        value: 'operationType',
        text: 'Operação',
      },

      {
        value: 'value',
        text: 'Valor',
        align: 'end',
      },

      {
        value: 'refDate',
        text: 'Cotização',
      },

      {
        value: 'settlementDate',
        text: 'Liquidação',
      },

      {
        value: 'unitaryPrice',
        text: 'Cota',
        align: 'end',
      },

      {
        value: 'quantity',
        text: 'Quantidade',
        align: 'end',
      },

      {
        value: 'performanceFee',
        text: 'Performance',
        align: 'end',
      },

      {
        value: 'redemptionCriterion',
        text: 'Critério',
      },
    ],
  }),

  computed: {
    overview: (vm) => getOverview(vm.items),
  },

  methods: {
    formatDate,
    formatMoney,
    formatNumber,
    formatPercentage,

    copyId(id) {
      try {
        copyToClipboard(id);

        this.$store.dispatch('notification', {
          text: `_id ${id} copiado com sucesso!`,
          status: true,
        });
      } catch (error) {
        this.$store.dispatch('notification', {
          text: `Não foi possível copiar o _id ${id}`,
          status: true,
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.totals-row td {
  position: sticky;
  top: 32px;

  background: #18324f;

  color: #fff;
  font-weight: 500;

  z-index: 2;
}
</style>
